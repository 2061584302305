import React from 'react'
import { Navbar, Footer } from '../Homepage/components';
import AdminSidebar from '../Homepage/components/AdminSidebar';
import { Carousel } from 'react-bootstrap';
import './AdminHomepage.css';
import FirstImage from '../Homepage/images/homepage-carousel1.jpg';
import SecondImage from '../Homepage/images/homepage-carousel2.jpg';
import ThirdImage from '../Homepage/images/homepage-carousel3.jpg';

import { useStateContext } from '../Homepage/contexts/ContextProvider';

const AdminHomepage = () => {
    const { activeMenu } = useStateContext();
    return (
        <div>
            <div className="flex relative dark:bg-main-dark-bg">
                <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

                {activeMenu ? (
                    <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                        <AdminSidebar />
                    </div>
                    ) : (
                    <div className="w-0 dark:bg-secondary-dark-bg">
                        <AdminSidebar />
                    </div>
                )}

                <div className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
                    <div className={"fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full"} style={{backgroundColor: "#E3DECA", textAlign: "center", alignContent: "center", alignItems: "center", margin: "auto"}}>
                        <Navbar />
                        <h2 className='TitleFont'>"Welcome To The Server, Admin!!"</h2>
                        <Carousel>
                            <Carousel.Item interval={1000}>
                            <img src={FirstImage} width={600} height={300} alt="First slide" className="ImageFormat"/>
                                <Carousel.Caption>
                                    <h2 className='TitleFont'>"Reign of Greed"</h2>
                                    <p>Ito ay mas kilala sa pangalan na "El Filibusterismo", ang kasunod na nobelang itinala ng ating pambansang bayani na si Dr. Jose Rizal </p>
                                    <br/><br/><br/>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={500}>
                            <img src={SecondImage} width={600} height={300} alt="Second slide" className="ImageFormat"/>
                                <Carousel.Caption>
                                    <h2 className='TitleFont'>"Reign of Greed: Game Adaptation"</h2>
                                    <p> Ang kilalang nobela sa kasaysayan ng Pilipinas, makikita niyo na sa inyong mga teknolohiya. </p>
                                    <br/><br/><br/>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                            <img src={ThirdImage} width={600} height={300} alt="Third slide" className="ImageFormat"/>
                                <Carousel.Caption>
                                    <h2 className='TitleFont'>"OMGDV Game Devs"</h2>
                                    <p> Isang pangkat ng mga manggagawa ng makabagong pamamaraan ng pagtangkilik upang magbigay ng kasiyahan sa mga manlalaro habang natututo na may kinalaman sa kasaysayan ng Pilipinas. </p>
                                    <br/><br/><br/>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminHomepage