import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore'
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvSEPHocgC9CB2LYwTNa4g8UWwGkloV3g",
  authDomain: "reignofgreed-6b11a.firebaseapp.com",
  databaseURL: "https://reignofgreed-6b11a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reignofgreed-6b11a",
  storageBucket: "reignofgreed-6b11a.appspot.com",
  messagingSenderId: "494160300981",
  appId: "1:494160300981:web:b8d8ff493c4cd8b94ce7fc",
  measurementId: "G-3TCMDETXSW"
};

  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const db = getFirestore(app);

  export { db };