import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TeacherLogin from './Loginpage/TeacherLogin';
import Homepage from './Homepage/pages/Homepage';
import ManageStudents from './Homepage/pages/ManageStudents';
import StudentsProgress from './Homepage/pages/StudentsProgress';
import QuestionBank from './Homepage/pages/QuestionBank';
import Developers from './Homepage/pages/Developers';

import AdminHomepage from './Adminpage/AdminHomepage';
import AdminManageStudents from './Adminpage/AdminManageStudents';
import AdminManageTeachers from './Adminpage/AdminManageTeachers';
import AdminQuestionBank from './Adminpage/AdminQuestionBank';
import AdminStudentsProgress from './Adminpage/AdminStudentProgress';

import Cookies from 'js-cookie';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if the user is logged in by verifying the cookie
    const loggedIn = Cookies.get('isLoggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  const handleLogin = () => {
    // Your authentication logic here to set `isLoggedIn`
    setIsLoggedIn(true);
    Cookies.set('isLoggedIn', 'true', { expires: 1, secure: true }); // Set the cookie with flags
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    Cookies.remove('isLoggedIn'); // Remove the cookie on logout
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to="/home" />
            ) : (
              <TeacherLogin setIsLoggedIn={setIsLoggedIn} /> // Pass setIsLoggedIn as a prop
            )
          }
        />
        {/* Protected Routes */}
        {isLoggedIn ? (
          <>
            <Route path="/home" element={<Homepage handleLogout={handleLogout} />} />
            <Route path="/manage students" element={<ManageStudents />} />
            <Route path="/students progress" element={<StudentsProgress />} />
            <Route path="/question bank" element={<QuestionBank />} />
            <Route path="/developers" element={<Developers />} />

            <Route path="/admin/home" element={<AdminHomepage/> }/>
            <Route path="/admin/manage teachers" element={<AdminManageTeachers/> }/>
            <Route path="/admin/manage students" element={<AdminManageStudents/> }/>
            <Route path="/admin/question bank" element={<AdminQuestionBank/> }/>
            <Route path="/admin/students progress" element={<AdminStudentsProgress/> }/>
          </>
        ) : (
          <Route path="/*" element={<Navigate to="/" />} />
        )}
      </Routes>
    </Router>
  );
};

export default App;
