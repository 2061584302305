import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { db } from '../firebase-config';
import { collection, getDocs, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { Navbar, Sidebar } from '../components';
import '../pages/QuestionBank.css';

import { useStateContext } from '../contexts/ContextProvider';
import QuestionBankModal from './Modals/QuestionBankModal';

const QuestionBank = () => {
  // USE-STATES FOR FIREBASE
  const [questions, setQuestions] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('chapter01');
  const chapterNumberRef = collection(db, 'quizzes');
  const questionCollectionReferenceChapter1 = collection(db, 'quizzes/chapter01/questions');
  const questionCollectionReferenceChapter2 = collection(db, 'quizzes/chapter02/questions');

  // STATE CONTEXT
  const { activeMenu } = useStateContext();
  const [anotherShow, setAnotherShow] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newOption1, setNewOption1] = useState('');
  const [newOption2, setNewOption2] = useState('');
  const [newOption3, setNewOption3] = useState('');
  const [newOption4, setNewOption4] = useState('');
  const [newAnswer, setNewAnswer] = useState('');

  const generateChapterHeader = () => {
    const chapterNumber = Number(selectedChapter.substring(7));
    let startChapter, endChapter;

    switch (chapterNumber) {
      case 1:
        startChapter = 'Kabanata I';
        endChapter = 'Kabanata III';
        break;
      case 2:
        startChapter = 'Kabanata IV';
        endChapter = 'Kabanata VI';
        break;
      case 3:
        startChapter = 'Kabanata VII';
        endChapter = 'Kabanata IX';
        break;
      case 4:
        startChapter = 'Kabanata X';
        endChapter = 'Kabanata XII';
        break;
      case 5:
        startChapter = 'Kabanata XIII';
        endChapter = 'Kabanata XV';
        break;
      case 6:
        startChapter = 'Kabanata XVI';
        endChapter = 'Kabanata XVIII';
        break;
      // Add cases for other chapters as needed
      default:
        startChapter = '';
        endChapter = '';
        break;
    }

    return `Lists in ${startChapter} - ${endChapter}`;
  };

  // Function to get questions from the database
  const getQuestions = async () => {
    try {
      let data;
      if (selectedChapter === 'chapter01') {
        data = await getDocs(questionCollectionReferenceChapter1);
      } else if (selectedChapter === 'chapter02') {
        data = await getDocs(questionCollectionReferenceChapter2);
      }

      if (data) {
        setQuestions(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  // Function to handle the addition of a new question
  const addNewQuestion = async () => {
    // Check if any of the required fields are empty
    if (
      newQuestion.trim() === '' ||
      newOption1.trim() === '' ||
      newOption2.trim() === '' ||
      newOption3.trim() === '' ||
      newOption4.trim() === '' ||
      newAnswer.trim() === ''
    ) {
      alert('Please fill in all required fields.');
    } else {
      // Check if the answer matches any of the options
      const options = [newOption1, newOption2, newOption3, newOption4];
      const isAnswerValid = options.includes(newAnswer);

      if (!isAnswerValid) {
        alert('The answer must match one of the provided options.');
        return; // Stop the function execution if the answer is not valid
      }

      // Add logic to handle the addition of a new question
      try {
        const questionData = {
          question: newQuestion,
          option1: newOption1,
          option2: newOption2,
          option3: newOption3,
          option4: newOption4,
          answer: newAnswer,
        };

        const questionCollectionReference =
          selectedChapter === 'chapter01'
            ? questionCollectionReferenceChapter1
            : questionCollectionReferenceChapter2;

        await addDoc(questionCollectionReference, questionData);

        // Refresh the questions by fetching them again from the database
        getQuestions();

        setAnotherShow(false);
      } catch (error) {
        console.error('Error adding question:', error);
      }
    }
  };

  useEffect(() => {
    // Call getQuestions function here to fetch initial data
    getQuestions();
  }, [selectedChapter]);

  const deleteQuestion = async (questionId) => {
    try {
      const questionCollectionReference =
        selectedChapter === 'chapter01'
          ? questionCollectionReferenceChapter1
          : questionCollectionReferenceChapter2;

      await deleteDoc(doc(questionCollectionReference, questionId));

      // Refresh the questions by fetching them again from the database
      getQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  const cellStyle = {
    // Add any common styling for table cells here
    padding: '10px',
  };

  const headerStyle = {
    // Style for question header
    fontFamily: 'Laila-Bold',
    fontSize: '1.5em',
    fontWeight: 'bold',
  };

  const optionStyle = {
    // Style for options
    fontFamily: 'Laila-Bold',
    fontSize: '1.2em',
  };

  const answerStyle = {
    // Style for answer
    fontFamily: 'Laila-Bold',
    fontSize: '1.2em',
  };

  const chapterTitleStyle = {
    borderBottom: '10px solid #E3DECA', // Add a border line
    height: '5px', // Minimum height
  };

  const toRomanNumerals = (number) => {
    const romanNumerals = ['I', 'II', 'III', 'IV', 'V']; // Add more as needed
    return romanNumerals[number - 1] || '';
  };

  // DISPLAY OUTPUT HERE
  return (
    <div>
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}

        <div
          style={{ backgroundColor: '#E3DECA' }}
          className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
            activeMenu ? 'md:ml-72' : 'flex-2'
          }`}
        >
          <div
            className={
              'fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'
            }
            style={{
              backgroundColor: '#E3DECA',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Navbar />
            <div style={{ marginLeft: '25px' }}>
              <Header
                category="Question Bank"
                title={`List of Questions For ${`Chapter ${toRomanNumerals(
                  Number(selectedChapter.substring(7))
                )}`}`}
              />

              <div className="dropdown-container" style={{ marginRight: '2%' }}>
              <select value={selectedChapter} onChange={(e) => setSelectedChapter(e.target.value)} className="dropdown-button">
                <option value="" disabled>Select Chapter</option>
                <option value="chapter01">Kabanata I - III</option>
                <option value="chapter02">Kabanata IV - VI</option>
                <option value="chapter03">Kabanata VII - IX</option>
                <option value="chapter04">Kabanata IX - XII</option>
                <option value="chapter05">Kabanata XIII - XV</option>
                <option value="chapter06">Kabanata XVI - XVIII</option>
              </select>
              </div>

              <button
                onClick={() => setAnotherShow(true)}
                style={{
                  fontFamily: 'LoginTitleFont',
                  fontSize: '15px',
                  color: '#E3DECA',
                  backgroundColor: '#3C3A1E',
                  borderRadius: '17px',
                  width: '15%',
                  height: '25px',
                  marginBottom: '20px',
                }}
              >
                Create New Question
              </button>
              <QuestionBankModal
                title="Add A New Question"
                show={anotherShow}
                onClose={() => setAnotherShow(false)}
              >
                <p>
                  <label>Question: </label>
                  <input
                    className="LoginInputField"
                    type="text"
                    placeholder="Enter the question..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewQuestion(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <label>Option #1: </label>
                  <input
                    className="LoginInputField"
                    placeholder="Enter the first option..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewOption1(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <label>Option #2</label>
                  <input
                    className="LoginInputField"
                    placeholder="Enter the second option..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewOption2(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <label>Option #3:</label>
                  <input
                    className="LoginInputField"
                    placeholder="Enter the third option..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewOption3(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <label>Option #4:</label>
                  <input
                    className="LoginInputField"
                    placeholder="Enter the fourth option..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewOption4(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <label>Answer:</label>
                  <input
                    className="LoginInputField"
                    placeholder="Please match the capitalization of the correct answer..."
                    style={{
                      width: '100%',
                      border: '1px solid #ccc',
                      borderStyle: 'inset',
                      borderRadius: '4px',
                      boxSizing: 'border-box',
                    }}
                    onChange={(event) => {
                      setNewAnswer(event.target.value);
                    }}
                    required
                  />
                  <br />
                  <br />
                  <b>
                    <button
                      onClick={addNewQuestion}
                      className="LoginButtons"
                      style={{
                        marginLeft: '30%',
                        width: '40%',
                        display: 'inline-block',
                      }}
                    >
                      Create
                    </button>
                  </b>
                </p>
              </QuestionBankModal>

              <table style={{ width: '98%' }}>
                <thead>
                  <tr style={chapterTitleStyle}>
                  <th colSpan="7">
                    List of questions in {generateChapterHeader()}
                  </th>
                  </tr>
                  <tr>
                    <th>Question</th>
                    <th>Option #1</th>
                    <th>Option #2</th>
                    <th>Option #3</th>
                    <th>Option #4</th>
                    <th>Answer</th>
                    <th>Modification</th>
                  </tr>
                </thead>

                <tbody>
                  {questions.map((qna) => {
                    return (
                      <tr key={qna.id}>
                        <td style={cellStyle}>
                          <h1 style={headerStyle}>{qna.question}</h1>
                        </td>
                        <td style={cellStyle}>
                          <h1 style={optionStyle}>{qna.option1}</h1>
                        </td>
                        <td style={cellStyle}>
                          <h1 style={optionStyle}>{qna.option2}</h1></td>
                      <td style={cellStyle}><h1 style={optionStyle}>{qna.option3}</h1></td>
                      <td style={cellStyle}><h1 style={optionStyle}>{qna.option4}</h1></td>
                      <td style={cellStyle}><h1 style={answerStyle}>{qna.answer}</h1></td>
                      <td>
                        <button onClick={() => deleteQuestion(qna.id)} style={{ fontFamily: 'LoginTitleFont', fontSize: '15px', color: '#E3DECA', backgroundColor: '#3C3A1E', borderRadius: '17px', width: '100%', height: 'auto', padding: '5px', cursor: 'pointer'}}>
                          Delete Question
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default QuestionBank;
