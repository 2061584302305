import React, { useState, useEffect, useRef } from 'react';

import { db } from '../Homepage/firebase-config'; //Firebase
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc, query, where, getDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, EmailAuthProvider, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from 'firebase/auth';

import { Header } from '../Homepage/components'; // Components
import { Navbar } from '../Homepage/components';
import AdminSidebar from '../Homepage/components/AdminSidebar';
import { useStateContext } from '../Homepage/contexts/ContextProvider';
import Papa from 'papaparse'; // Import Papaparse for CSV parsing

import ManageStudentsModal from '../Homepage/pages/Modals/ManageStudentsModal'; // Modals
import UpdateStudentsModal from '../Homepage/pages/Modals/UpdateStudentsModal'

import '../Homepage/pages/ManageStudents.css'; // CSS

function AdminManageStudents() {
    {/* USE-STATES FOR FIREBASE */ }
    const [users, setUsers] = useState([]);
    const [newName, setNewName] = useState("");
    const [newLrn, setNewLrn] = useState(0);
    const [newSection, setNewSection] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newTeacher, setNewTeacher] = useState("");
    const [newStatus, setNewStatus] = useState(false);
    const usersCollectionRef = collection(db, "users");

    const [updateName, setUpdateName] = useState("");
    const [updateLrn, setUpdateLrn] = useState(0);
    const [updateSection, setUpdateSection] = useState("");
    const [updateEmail, setUpdateEmail] = useState("");
    const [updateTeacher, setUpdateTeacher] = useState("");
    const [updateStatus, setUpdateStatus] = useState(false);

    {/* STATE CONTEXT FOR SIDEBAR */}
    const { activeMenu } = useStateContext();
    
    {/* USE-STATES FOR MODAL */ }
    const [show, setShow] = useState(false);
    const [anotherShow, setAnotherShow] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const auth = getAuth();
    
    // Create a ref for the input element
    const csvInputRef = useRef(null);

    const handleCheckboxChange = (event, userId) => {
        const isChecked = event.target.checked;
    
        setSelectedUsers((prevSelected) => {
          if (isChecked) {
            return [...prevSelected, userId]; // Add user ID to selected list
          } else {
            return prevSelected.filter((id) => id !== userId); // Remove user ID from selected list
          }
        });
    };

    // Function to trigger file upload when button is clicked
    const triggerFileUpload = () => {
        if (csvInputRef.current) {
            csvInputRef.current.click(); // Trigger file input click event
        }
    };

    const handleCSVUpload = async (event) => {
        const file = event.target.files[0];
    
        if (file) {
            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    try {
                        const data = results.data;
                        const invalidEmailRows = [];

                        for (const item of data) {
                            const email = item['email'];
    
                            if (email && validateEmail(email)) {
                                try {
                                    const usersQuerySnapshot = await getDocs(query(collection(db, 'users'), where('email', '==', email)));
                                    
                                    if (!usersQuerySnapshot.empty) {
                                        window.alert('Email already exists. Please use a different email.');
                                    } else {
                                        const newPassword = item['lrn'];
                                        const userCredential = await createUserWithEmailAndPassword(getAuth(), email, newPassword);
                                        const user = userCredential.user;
    
                                        await addDoc(collection(db, "users"), {
                                            name: item['name'],
                                            section: item['section'],
                                            lrn: Number(item['lrn']),
                                            email: email,
                                            teacher: item['teacher'],
                                            status: false,
                                            uid: user.uid
                                        });
    
                                        window.alert('User created successfully!');
                                    }
                                } catch (error) {
                                    window.alert('Error creating user:', error);
                                    // Handle error creating user
                                }
                            } else {
                                invalidEmailRows.push(item); // Store rows with invalid or missing emails
                            }
                        }
                    } catch (error) {
                        console.error('Error parsing CSV:', error);
                        // Handle parsing errors here
                    }
                },
                error: (error) => {
                    console.error('Error parsing CSV:', error);
                    // Handle parsing errors here
                },
            });
        }
    };

    // Validate email function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };  

    {/* CREATE FUNCTION TO DATABASE */ }
    const createUser = async () => {
    if (
        newName.trim() === '' ||
        newLrn === 0 ||
        newSection.trim() === '' ||
        newEmail.trim() === '' ||
        newTeacher.trim() === ''
    ) {
        alert('Please fill in all required fields.');
    } else {
        try {
            // Check if the email already exists in Firebase Authentication
            const existingUser = await getAuth().fetchSignInMethodsForEmail(newEmail);

            if (existingUser.length > 0) {
                alert('Email already exists. Please use a different email.');
            } else {
                var newPassword = newLrn;
                const userCredential = await createUserWithEmailAndPassword(auth, newEmail, newPassword);
                const user = userCredential.user;
                
                // Add the user to Firestore
                await addDoc(usersCollectionRef, {
                    name: newName,
                    section: newSection,
                    lrn: Number(newLrn),
                    email: newEmail,
                    teacher: newTeacher,
                    status: newStatus,
                    uid: user.uid // Save the user's UID to the Firestore document
                });

                // Show an alert for successful user creation
                window.alert('User created successfully!');
                getUsers();
                setShow(false); // Close the modal after adding user
            }
        } catch (error) {
            console.error('Error creating user: ', error.message);
            // Handle error appropriately (display error message, etc.)
        }
    }
    };


    {/* READ FUNCTION FROM DATABASE */ }
    const getUsers = async () => {
        try {
            const querySnapshot = await getDocs(usersCollectionRef);
            const userData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
            console.log("Fetched users from Firestore:", userData);
            setUsers(userData);
        } catch (error) {
            console.error("Error getting users: ", error);
        }
    };

    useEffect(() => {
        console.log("Fetching users...");
        getUsers(); // Fetch users on initial render
    }, []);

    {/* UPDATE FUNCTION FROM DATABASE */ }
    const applyUserUpdates = async (user) => {
        try {
            const updatedUserData = {
                name: updateName || user.name,
                section: updateSection || user.section,
                lrn: Number(updateLrn) || user.lrn,
                email: updateEmail || user.email,
                teacher: updateTeacher || user.teacher,
                status: updateStatus || user.status,
            };
    
            const userDocRef = doc(db, "users", user.id);
    
            await updateDoc(userDocRef, updatedUserData);
            getUsers();
            setAnotherShow(false);
            // Reset the state values after updating
            setUpdateName("");
            setUpdateLrn(0);
            setUpdateSection("");
            setUpdateEmail("");
            setUpdateTeacher("");
            setUpdateStatus(false);

            window.alert("Student's information has been updated successfully!");
        } catch (error) {
            console.error("Error updating user: ", error);
        }
    };

    {/* DELETE FUNCTION IN DATABASE */ }
    const deleteUser = async (id) => {
        const userDoc = doc(db, "users", id);
    
        try {
            const userSnapshot = await getDoc(userDoc);
            const userData = userSnapshot.data();
    
            if (userData) {
                const email = userData.email; // Access the user's email from Firestore data
                const password = prompt("Please enter your password to confirm account deletion.");
    
                if (password !== null) {
                    const credentials = EmailAuthProvider.credential(email, password);
                    const currentUser = auth.currentUser;
    
                    try {
                        // Re-authenticate the user with the provided credentials
                        const credentialResponse = await signInWithEmailAndPassword(auth, email, password);
    
                        // Check if re-authentication is successful
                        if (credentialResponse.user) {
                            // Delete the user document from Firestore
                            await deleteDoc(userDoc);
    
                            // Delete the user from Firebase Authentication
                            await currentUser.delete();
    
                            window.alert('User deleted successfully!');
                            setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
                        } else {
                            window.alert('Re-authentication failed.');
                        }
                    } catch (error) {
                        console.error('Error re-authenticating user or deleting account:', error);
                        window.alert('Error: ' + error.message);
                        // Handle error appropriately
                    }
                } else {
                    window.alert('Password is required to delete the account.');
                }
            } else {
                window.alert('User data not found!');
            }
        } catch (error) {
            console.error('Error deleting user: ', error);
            window.alert('Error deleting user: ' + error.message);
            // Handle error appropriately
        }
    };
      
    
    
    // HANDLE STATUS
    const handleConfirmReject = async (confirm) => {
        const checkedUsers = users.filter((u) => u.isChecked && !u.status); // Exclude verified users

        try {
            for (const user of checkedUsers) {
                const userDocRef = doc(db, 'users', user.id);

                if (confirm) {
                    const updatedUserData = { ...user, status: true }; // Update status to verified
                    await updateDoc(userDocRef, updatedUserData);
                    window.alert(`${user.name}'s status has been verified!`);
                } else {
                    // Only delete unverified users
                    // Delete the user from Firebase Authentication
                    const authUser = await getAuth().currentUser;
                    await authUser.delete();

                    // Delete the user from Firestore
                    await deleteDoc(userDocRef);

                    window.alert(`${user.name}'s status has been rejected,`);
                }
            }

            getUsers();
        } catch (error) {
            console.error('Error updating/deleting users: ', error);
        }
    };

    return (
    <div>
        <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

            {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                    <AdminSidebar />
                </div>
                ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                    <AdminSidebar />
                </div>
            )}

            <div style={{backgroundColor: "#E3DECA"}} className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
                <div className={"fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full"} style={{backgroundColor: "#E3DECA"}}>
                    <Navbar/>
                    <div className="ManageStudents" style={{paddingLeft: '25px'}}>
                        <Header category="Manage Students" title="List of Students"/>
                        <div>
                            <b>
                                <button onClick={() => setShow(true)} style={{fontFamily: 'LoginTitleFont', fontSize: '15px', color: '#E3DECA', backgroundColor: '#3C3A1E', borderRadius: '17px', width: '10%', height: '25px', marginRight: '30px'}}>
                                    Create New User
                                </button>{'\u00A0'}
                                <button
                                        onClick={triggerFileUpload}
                                        style={{
                                        fontFamily: 'LoginTitleFont',
                                        fontSize: '15px',
                                        color: '#E3DECA',
                                        backgroundColor: '#3C3A1E',
                                        borderRadius: '17px',
                                        width: '10%',
                                        height: '25px'
                                    }}>
                                        Upload CSV File
                                    </button>

                                    {/* Hidden input for file upload */}
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={handleCSVUpload}
                                        style={{ display: 'none' }}
                                        ref={csvInputRef}
                                    />{'\u00A0'}
                                <ManageStudentsModal title="Create A New User" show={show} onClose={() => setShow(false)}>
                                    <p>
                                        <label>Full Name:</label>
                                        <input className='LoginInputField' placeholder='Please enter the full name of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewName(event.target.value) }}/><br/><br/>
                                        <label>Learning Reference Number "LRN":</label>
                                        <input className='LoginInputField' type='number' placeholder='Please enter the LRN of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewLrn(event.target.value) }} /><br/><br/>
                                        <label>Year & Section:</label>
                                        <input className='LoginInputField' placeholder='Please enter the year and section of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewSection(event.target.value) }} /><br/><br/>
                                        <label>Email:</label>
                                        <input className='LoginInputField' placeholder='Please enter the student`s email here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewEmail(event.target.value) }}/><br/><br/>
                                        <label>Teacher's Full Name:</label>
                                        <input className='LoginInputField' placeholder='Please enter the teacher`s name of the student here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewTeacher(event.target.value) }}/><br/><br/>
                                        <b><button onClick={createUser} className='LoginButtons' style={{marginLeft: "30%", width: "40%", display: "inline-block"}}> Create User </button></b>
                                    </p>
                                </ManageStudentsModal>
                            </b>    
                            {users.filter((user) => user.isChecked).length > 0 && (
                            <div style={{justifyContent: 'flex-end', gap: '20px' }}>
                                <br/>
                                <strong> Verification Action: </strong>
                                <button
                                    style={{
                                        fontFamily: 'LoginTitleFont',
                                        fontSize: '15px',
                                        color: '#E3DECA',
                                        backgroundColor: '#3C3A1E',
                                        borderRadius: '17px',
                                        width: '10%',
                                        height: '25px',
                                        marginRight: '1.5%',  // Adjusted marginLeft
                                        marginLeft: '1.5%',  // Adjusted marginLeft
                                    }}
                                    onClick={() => handleConfirmReject(true)}
                                >
                                    Confirm Selected
                                </button>
                                <button
                                    style={{
                                        fontFamily: 'LoginTitleFont',
                                        fontSize: '15px',
                                        color: '#E3DECA',
                                        backgroundColor: '#3C3A1E',
                                        borderRadius: '17px',
                                        width: '10%',
                                        height: '25px',
                                        marginRight: '1.5%',  // Adjusted marginLeft
                                        marginLeft: '1.5%',  // Adjusted marginLeft
                                    }}
                                    onClick={() => handleConfirmReject(false)}
                                >
                                    Reject Selected
                                </button>
                            </div>
                        )}
                        </div>
                        <br />
                        <table style={{width: "98.5%", height: "100%", marginBottom: "2%"}}> 
                            <thead>
                                <tr>
                                    <th style={{width: "5%"}}></th>
                                    <th>Full Name</th>
                                    <th>Student's LRN </th>
                                    <th>Section</th>
                                    <th>Email</th>
                                    <th>Teacher's Full Name</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody style={{overflowY: "auto"}}>
                            {users.map((user) => {
                                return (
                                    <tr key={user.id}>
                                        <td style={{ border: "0px" }}>
                                            {!user.status ? (
                                                <input
                                                    type="checkbox"
                                                    checked={user.isChecked || false}
                                                    onChange={(event) => {
                                                        const isChecked = event.target.checked;
                                                        const updatedUsers = users.map((u) => {
                                                            if (u.id === user.id) {
                                                                return { ...u, isChecked };
                                                            }
                                                            return u;
                                                        });
                                                        setUsers(updatedUsers);
                                                    }}
                                                    style={{ transform: 'scale(0.3)', float: 'right' }}
                                                />
                                            ) : null}
                                        </td>
                                        <td><h1>{user.name}</h1></td>
                                        <td><h1>{user.lrn}</h1></td>
                                        <td><h1>{user.section}</h1></td>
                                        <td><h1>{user.email}</h1></td>
                                        <td><h1>{user.teacher}</h1></td>
                                        <td><h1>{user.status ? 'Verified' : 'Not Verified'}</h1></td>
                                        <td style={{margin: "auto"}}>
                                            <button onClick={() => setAnotherShow(true)} style={{ fontFamily: 'LoginTitleFont', fontSize: "15px", margin: "2%", color: "#E3DECA", backgroundColor: "#3C3A1E", borderRadius: "17px", width: "50%", height: "25px" }}>Update Student</button>
                                            <UpdateStudentsModal title="Update Student's Information" show={anotherShow} onClose={() => setAnotherShow(false)}>
                                                <p>
                                                    <label>Full Name:</label>
                                                    <input className='LoginInputField' placeholder='Please enter the full name of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setUpdateName(event.target.value) }} value={updateName}/><br/><br/>
                                                    <label>Learning Reference Number "LRN":</label>
                                                    <input className='LoginInputField' type='number' placeholder='Please enter the LRN of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setUpdateLrn(event.target.value) }} value={updateLrn}/><br/><br/>
                                                    <label>Year & Section:</label>
                                                    <input className='LoginInputField' placeholder='Please enter the year and section of the student...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setUpdateSection(event.target.value) }} value={updateSection} /><br/><br/>
                                                    <label>Email:</label>
                                                    <input className='LoginInputField' placeholder='Please enter the student`s email here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setUpdateEmail(event.target.value) }} value={updateEmail}/><br/><br/>
                                                    <label>Teacher's Full Name:</label>
                                                    <input className='LoginInputField' placeholder='Please enter the teacher`s name of the student here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setUpdateTeacher(event.target.value) }} value={updateTeacher}/><br/><br/>
                                                    <b><button onClick={() => applyUserUpdates(user)} className='LoginButtons' style={{marginLeft: "30%", width: "40%", display: "inline-block"}}> Apply Changes </button></b>
                                                </p>
                                            </UpdateStudentsModal>
                                            <button style={{fontFamily: 'LoginTitleFont', fontSize: "15px", margin: "2%", color: "#E3DECA", backgroundColor: "#3C3A1E", borderRadius: "17px", width: "50%", height: "25px"}} onClick={() => {deleteUser(user.id)}}>Delete Student</button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default AdminManageStudents;
