import React, { useState, useEffect } from 'react';
import { db } from '../Homepage/firebase-config';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { Header } from '../Homepage/components';
import ManageStudentsModal from '../Homepage/pages/Modals/ManageStudentsModal';
import '../Homepage/pages/ManageStudents.css';
import { Navbar } from '../Homepage/components';
import AdminSidebar from '../Homepage/components/AdminSidebar';
import { useStateContext } from '../Homepage/contexts/ContextProvider';
import '../Homepage/pages/StudentsProgress.css';

function StudentsProgress() {
  const [users, setUsers] = useState([]);
  const [chapterScoreType, setChapterScoreType] = useState('chapter1score'); // Default to chapter 1 score
  const usersCollectionRef = collection(db, 'users');
  const { activeMenu } = useStateContext();

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUsers();
  }, [usersCollectionRef]);

  return (
    <div>
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <AdminSidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <AdminSidebar />
          </div>
        )}

        <div style={{ backgroundColor: '#E3DECA' }} className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
          <div className={'fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full'} style={{ backgroundColor: '#E3DECA' }}>
            <Navbar />
            <div className="ManageStudents" style={{ marginLeft: '25px' }}>
              <Header category="Students Progress" title="Game Progress of Students" />
              <br /> <br />

              {/* Styled Dropdown to select chapter scores */}
              <div className="dropdown-container">
                <label>Select Chapter Scores:</label>
                <div className="styled-dropdown">
                <select
                  value={chapterScoreType}
                  onChange={(e) => setChapterScoreType(e.target.value)}
                >
                  <option value="" disabled>Select Chapter Score</option>
                  <option value="chapter1score">Kabanata I - III Quiz Score</option>
                  <option value="chapter2score">Kabanata IV - VI Quiz Score</option>
                  <option value="chapter3score">Kabanata VII - IX Quiz Score</option>
                  <option value="chapter4score">Kabanata X - XII Quiz Score</option>
                  <option value="chapter5score">Kabanata XIII - XV Quiz Score</option>
                  {/* Add more options as needed */}
                </select>
                </div>
              </div>

              <table style={{ width: '98.5%', height: '100%', marginBottom: '2%' }}>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Student's LRN </th>
                    <th>Section</th>
                    <th style={{ width: '10%' }}>Chapter Score</th>
                    <th style={{ width: '10%' }}>No. of Items</th>
                  </tr>
                </thead>

                <tbody style={{ overflowY: 'auto' }}>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <h1>{user.name}</h1>
                      </td>
                      <td>
                        <h1>{user.lrn}</h1>
                      </td>
                      <td>
                        <h1>{user.section}</h1>
                      </td>
                      <td>
                        <h1>{user[chapterScoreType] || 'n/a'}</h1>
                      </td>
                      <td>
                        <h1>{user.chapter1outof || 10}</h1>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentsProgress;
