import React from 'react';
import ReactDOM from 'react-dom';
import './ManageStudentsModal.css';
import { CSSTransition } from 'react-transition-group';

const AdminManageTeachersModal = props => {
    return ReactDOM.createPortal(
        <CSSTransition in={props.show} unmountOnExit timeout={{ enter: 0, exit: 300}}>
            <div className="modal">
                <div className='modal-content'>
                <button className='button' onClick={props.onClose} style={{float: 'right', marginRight: '5px', marginTop: '-5px', fontSize: '30px'}}> &times; </button>
                    <div className='modal-header'>
                        <h4 className='modal-title'>
                            <strong style={{fontSize: "30px"}}> Create A New Teacher </strong>
                        </h4>
                    </div>
                    <div className='modal-body'>
                        {props.children}
                    </div>
                    <div className='modal-footer'>
                        {props.button}
                    </div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementById("root")
    );
}

export default AdminManageTeachersModal