import React, { useEffect } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'

import { useStateContext } from '../contexts/ContextProvider'
import { useNavigate } from 'react-router-dom';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button type="button" 
            onClick={customFunc}
            style={{ color }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray">
      <span style={{ background: dotColor }}
            className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"/>
        {icon}
    </button>
  </TooltipComponent>
)

const Navbar = () => {
  const { activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize, setScreenSize } = useStateContext();

  const navigate = useNavigate();
  
  const LogOut = () => {
    
    window.location.href = '/'; // Redirect to the home or login page
    // Perform other logout tasks if necessary.
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize)
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(screenSize <= 900)
    {
      setActiveMenu(false);
    }
    else
    {
      setActiveMenu(true);
    }
  }, [screenSize]); 

  return (
    <div className="flex justify-between p-2 md:mx-6 relative" style={{backgroundColor: "#E3DECA"}}>
      <NavButton title="Menu" customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)} color="black" icon={<AiOutlineMenu/>}/>
      
      <div style={{display: "flex", borderStyle: "solid", borderWidth: "medium", borderColor: "#E3DECA", backgroundColor: "#E3DECA", height: "100%", width: "22.5%"}}>
        <button style={{fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "2%"}}>CONTACT{'\u00A0'}{'\u00A0'}</button>
        <button style={{fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "2%"}}>ABOUT{'\u00A0'}{'\u00A0'}</button>
        <button style={{ fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "2%", color: "#E3DECA", backgroundColor: "#3C3A1E", borderRadius: "17px", width: "80%", height: "25px" }} onClick={LogOut}>LOG-OUT</button>
      </div>
    </div>
  )
}

export default Navbar