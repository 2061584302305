import React from 'react'
import { Header } from '../components';
import { Navbar, Footer, Sidebar } from '../components';

import { useStateContext } from '../contexts/ContextProvider';

const Developers = () => {
  const { activeMenu } = useStateContext();

  return (
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
            <Sidebar />
          </div>
          ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}

        <div style={{backgroundColor: "#E3DECA"}} className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
          <div className={"fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full"} style={{backgroundColor: "#E3DECA", alignContent: "center", alignItems: "center"}}>
            <Navbar/>
            <div style={{marginLeft: "2%"}}> 
              <Header category="About Developers" title="Who Are We?"/>
              <p style={{fontSize: '30px', marginTop: '-30px', textAlign: 'center'}}> Welcome to OMGDV - Where Innovation Meets Education in Gaming! </p>
      
              {/* FORMAT FIXER */}
              {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
      
              <Header title="About Us"/>
              <p style={{marginTop: '-25px'}}>
                At OMGDV, we're not just game developers; we are creators of immersive and educational experiences that empower students through technological innovation. We understand the power of gaming to engage, educate, and inspire. Our passion for gaming development goes beyond entertainment – we're on a mission to reshape education through interactive, mind-nurturing games.
              </p><br/><br/>

              <Header title="Our Journey"/>
              <p style={{marginTop: '-25px'}}>
                Founded at September 2, 2023. OMGDV has been at the forefront of the gaming development industry for 2 years. Our journey as a student developers began with a vision to create more than just games; we aspired to build a bridge between entertainment and education. With every game we develop, we seek to unlock the full potential of interactive media as a tool for learning.
              </p><br/><br/>

              <Header title="Our Expertise"/>
              <p style={{marginTop: '-25px'}}>
                        OMGDV boasts a diverse team of skilled developers, designers, and educators who share a common purpose - to harness the exciting world of gaming for educational enrichment. With a combined experience of over [Y] years in the gaming industry, we've honed our craft to deliver top-quality, thought-provoking games.
              </p><br/><br/>

              <Header title="Our Objective"/>
              <p style={{marginTop: '-25px'}}>
                <strong> Our Objective: </strong>
                To create a game that can be utilized as an educational tool that nurtures the mind and experience of students through technological innovation.
              </p><br/>
              <p style={{marginTop: '-10px'}}>
                At OMGDV, our primary objective is clear: we aim to revolutionize the way students learn through our gaming creations. We believe that learning can be enjoyable, interactive, and highly effective. Our games are meticulously designed to stimulate critical thinking, problem-solving, and creativity while covering a wide array of educational subjects.
              </p><br/><br/>

              <Header title="Our Committment"/>
              <p style={{marginTop: '-25px'}}>
                <strong>We are committed to crafting games that:</strong>
                <ul>
                  <li>• Promote learning through immersive experiences.</li>
                  <li>• Encourage curiosity and exploration.</li>
                  <li>• Foster collaboration and communication among students.</li>
                  <li>• Adapt to individual learning styles.</li>
                  <li>• Align with educational curricula and standards.</li>
                </ul>
              </p><br/><br/>

              <Header title="Join Us on the Journey"/>
              <p style={{marginTop: '-25px'}}>
                At OMGDV, we invite you to join us on this exciting journey to redefine education through gaming. Our commitment to innovation, education, and gaming excellence drives us to create exceptional products that inspire and educate the minds of students worldwide.
              </p>
              <p style={{marginTop: '10px'}}>
                Contact us to learn more about our projects, partnerships, and how we're contributing to a brighter educational future through gaming!
              </p><br/><br/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Developers