import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ContextProvider } from './Homepage/contexts/ContextProvider';
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF1cXGFCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9edHVTRGBeUEB3WkM=');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ContextProvider>
        <App/>
    </ContextProvider>,
);