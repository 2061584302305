import React, { useState, useEffect } from 'react'; // Navigate, Router and other JS Components
import { Routes, Route, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import Homepage from '../Homepage/pages/Homepage';  // Webpages
import AdminHomepage from '../Adminpage/AdminHomepage';
import ManageStudents from '../Homepage/pages/ManageStudents';
import StudentsProgress from '../Homepage/pages/StudentsProgress';

import TeacherLoginModal from '../Loginpage/Modals/TeacherLoginModal'; // Components
import AboutInfoModal from '../Loginpage/Modals/AboutInfoModal';
import ContactInfoModal from '../Loginpage/Modals/ContactInfoModal';

import LoginLogo from "../Homepage/images/login-logo.png";  // Images
import Simoun from '../Homepage/images/login-simoun.png';
import EmailLogo from "../Homepage/images/logo-email.png";
import FacebookLogo from "../Homepage/images/logo-facebook.png";  

import { db } from '../Homepage/firebase-config'; // Firebase
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';

import "../Loginpage/TeacherLogin.css"; // CSS
<meta name="viewport" content="width=device-width" initial-scale="1.00" maximum-scale="1.0" />

const TeacherLogin = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  {/* FOR LOGIN */}
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  {/* FOR REGISTRATION */}
  const [newFirstname, setNewFirstname] = useState("");
  const [newMiddlename, setNewMiddlename] = useState("");
  const [newLastname, setNewLastname] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [prcNumber, setPrcNumber] = useState('');
  const [newUsername, setNewUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newStatus, setNewStats] = useState(false);

  const usersCollectionRef = collection(db, "teachers");
  const adminCollectionRef = collection(db, 'admin');

  const navigateHome = async () => {
    await loginUser(); // Call loginUser function
  };

  const loginUser = async () => {
    const userQuerySnapshot = await getDocs(usersCollectionRef);
    const adminQuerySnapshot = await getDocs(adminCollectionRef); // Retrieve admin accounts
  
    let found = false;
    let nextfound = false;
  
    userQuerySnapshot.forEach((doc) => {
      const userData = doc.data();
  
      if (userData.username === username && userData.password === password) {
        if (userData.status) {
          alert('You have been logged in successfully!');
          setIsLoggedIn(true);
          found = true;
          return;
        } else {
          alert('Your account is inactive or restricted. Please contact support.');
          found = true;
          return;
        }
      }
    });
  
    // Check admin accounts
    adminQuerySnapshot.forEach((doc) => {
      const adminData = doc.data();
      if (adminData.username === username && adminData.password === password) {
        alert('You have been logged in successfully as an admin!');
        setIsLoggedIn(true);
        nextfound = true;
        return;
      }
    });
  
    if (found) {
      navigate("/home");
    } else if (nextfound) {
      navigate("/admin/home");
    } else {
      alert('Invalid Username/Password: Please re-enter your account information.');
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const createTeacherAccount = async () => {
    // Check if any of the required fields are empty
    if (
      newUsername.trim() === '' ||
      newPassword === '' ||
      newFirstname.trim() === '' ||
      newMiddlename.trim() === '' ||
      newLastname.trim() === '' ||
      newEmail.trim() === '' ||
      prcNumber.trim() === ''
    ) {
      alert('Please fill in all required fields.');
    } else {
      if (newPassword !== confirmPassword) {
        alert("Passwords do not match. Please enter them again.");
        return;
      }
      else
      {
        const querySnapshot = await getDocs(usersCollectionRef);

        let usernameExists = false;

        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          if (userData.username === newUsername) {
            usernameExists = true;
          }
        });

        // Check if the username already exists
        if (usernameExists) {
          alert('Username already exists. Please choose a different one.');
        } else {
          // If username is not taken, proceed with registration
          await addDoc(usersCollectionRef, {
            firstname: newFirstname,
            middlename: newMiddlename,
            lastname: newLastname,
            email: newEmail,
            prcnumber: prcNumber,
            username: newUsername,
            password: newPassword,
            status: newStatus,
          });
        alert('Registration successful!'); // Display a success message
        // Optionally, you can navigate to the home page or handle it as needed
        // navigate('/home');
        }
      }
    }
  };

  const openVerificationEmail = () => {
    const emailSubject = encodeURIComponent('Account Verification');
    const emailTo = encodeURIComponent('reignofgreed.omgdv@gmail.com');
    
    // Include the email template in the emailBody
    const emailBody = encodeURIComponent(`
        Good Morning/Evening Admin!

        I hope this message finds you well. My name is [Your Full Name], and I am currently a teacher at Meycauayan National High School. I am writing to request the verification of my account for Reign of Greed - Server Side.

        Here are my account details:
        - Username: [Your Username]
        - Email: [Your Email Address]

        I understand the importance of account verification for security and compliance purposes. Please review my request and initiate the verification process at your earliest convenience.

        ![ID Verification](url_to_image)

        If you require any additional information or documentation for the verification process, please let me know, and I will be happy to provide it promptly.

        Thank you for your attention to this matter. I appreciate your efforts in ensuring the integrity and security of the Reign of Greed - Server Side.
    `);

    // Use template literals to include the email body in the URL
    const emailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailTo}&su=${emailSubject}&body=${emailBody}`;

    // Open the email in a new tab or window
    window.open(emailUrl, '_blank');
  };

  return (
    <div style={{overflow: "hidden"}}>
      <div style={{borderStyle: "solid", borderWidth: "medium", borderColor: "#E3DECA", backgroundColor: "#E3DECA", height: "100%"}}>

        <img src={LoginLogo} alt="School Logo" height={45} width={45} color="#3C3A1E" className="ImageColor" style={{marginTop: "0px", marginLeft: "7.5%", marginBottom: "11px", display: "inline-block"}}/>
        <p style={{fontFamily: "LoginTitleFont", fontSize: "20px", display: "inline-block", marginTop: "20px", color: "#3C3A1E"}}>{'\u00A0'}REIGN of GREED</p>

        <button className="LoginButtons" onClick={openVerificationEmail} style={{ fontFamily: 'LoginTitleFont', fontSize: '15px', float: 'right', margin: '0.8%', width: "10%"}}>
          VERIFY ACCOUNT
        </button>
            
        <button className='LoginButtons' data-tip="Sign up for a new account" style={{fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "0.8%"}} onClick={() => setShow(true)}> SIGN-UP</button>
          <TeacherLoginModal title="Register Teacher User" show={show} onClose={() => setShow(false)}>
            <p>
              <label><b>Teacher's First Name:</b></label>
              <input className='LoginInputField' placeholder='Enter your first name here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewFirstname(event.target.value) }}/><br/><br/>
              <label><b>Teacher's Middle Name:</b></label>
              <input className='LoginInputField' placeholder='Enter your middle name here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewMiddlename(event.target.value) }}/><br/><br/>
              <label><b>Teacher's Last Name:</b></label>
              <input className='LoginInputField' placeholder='Enter your last name here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewLastname(event.target.value) }}/><br/><br/>
              <label><b>Teacher's Email:</b></label>
              <input className='LoginInputField' placeholder='Enter your email here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewEmail(event.target.value) }}/><br/><br/>
              <label><b>PRC Number:</b></label>   
              <input className='LoginInputField' placeholder='Enter your PRC number here...' style={{ width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setPrcNumber(event.target.value) }}/><br/><br/>
              <label><b>Teacher's Username:</b></label>
              <input className='LoginInputField' placeholder='Enter your username here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewUsername(event.target.value) }}/><br/><br/>
              <label><b>Password:</b></label>
              <input className='LoginInputField' type='password' placeholder='Enter your password here...' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setNewPassword(event.target.value) }} /><br/><br/>
              <label><b>Confirm Password:</b></label>
              <input className='LoginInputField' type='password' placeholder='Re-enter your password here...  ' style={{width: "100%", border: "1px solid #ccc", borderStyle: "inset", borderRadius: "4px", boxSizing: "border-box"}} required onChange={(event) => { setConfirmPassword(event.target.value) }}s /><br/><br/>
              <br/><br/>
              <b><button onClick={createTeacherAccount} className='LoginButtons' style={{marginLeft: "30%", width: "40%", display: "inline-block"}}> Confirm Registration </button></b>
            </p>
          </TeacherLoginModal>


        <button  data-tip="Contact us" onClick={() => setShowContact(true)} style={{fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "0.8%"}}>CONTACT</button>
        <ContactInfoModal title="Who Are We?" show={showContact} onClose={() => setShowContact(false)}>
          <img src={EmailLogo} height={25} width={25} style={{display: "inline"}}/><strong>: reignofgreed.omgdv@gmail.com</strong><br/>
        </ContactInfoModal>


        <button data-tip="Learn more about us" onClick={() => setShowAbout(true)} style={{fontFamily: 'LoginTitleFont', fontSize: "15px", float: "right", margin: "0.8%"}}>ABOUT</button>
        <AboutInfoModal title="Who Are We?" show={showAbout} onClose={() => setShowAbout(false)}>
          <p>
            <strong> We are OMGDV, an organization of game developers. Reign of Greed is one of the project in compliance of offering an unique and innovative method of providing education that allows to further engage and nurture students. </strong><br/><br/>
            <strong> Our research team are focused to offer the educational game service in Meycauyan National High School, one of the public schools in the Philippines.</strong><br/><br/>
          </p>
        </AboutInfoModal>
      </div>

      <div style={{backgroundColor: "#E3DECA", height: "760px", borderStyle: "solid", borderWidth: "medium", borderColor: "#E3DECA"}}>
        <div style={{marginLeft: "7.5%", marginTop: "7.5%", backgroundColor: "#5C2C0C", borderRadius: "25px 25px 0px 0px", padding: "20px", width: "25%", height: "100px", boxShadow: "30px 14px 15px 0px #00000040"}}>
          <div style={{padding: "5%"}}>
            <b><p style={{fontFamily: "LoginFormTitleFont", fontWeight: "700", fontSize: "110%", color: "#FFFFFF", textAlign: "center"}}>
              MALIGAYANG ARAW GURO!
            </p></b>
          </div>
        </div>
        
        <Routes> {/* Use Routes here */}
          <Route path="/home" element={<Homepage/>}/>
          <Route path="/manage students" element={<ManageStudents/>}/>
          <Route path="/students progress" element={<StudentsProgress />} />
          <Route path="/admin/home" element={<AdminHomepage />} />
        </Routes> 

        <div style={{marginLeft: "7.5%", backgroundColor: "#D6CEB1", borderStyle: "solid", borderWidth: "medium", borderRadius: "0px 0px 25px 25px", padding: "20px", borderColor: "#D6CEB1", width: "25%", height: "425px", boxShadow: "30px 14px 15px 0px #00000040"}}>
          <div><br/>
            <div style={{margin: "5%"}}>
              <label for="username"><b>Username:</b></label><br/>
              <input type="name" placeholder="Enter your username..." required onChange={(event) => { setUsername(event.target.value) }} className='LoginInputField'/>
              <br/><br/>
              <label for="password"><b>Password:</b></label><br/>
              <input type="password" placeholder="Enter your password..." required onChange={(event) => { setPassword(event.target.value) }} className='LoginInputField'/>
              <br/><br/><br/><br/><br/>
              <button className='LoginButtons' style={{marginLeft: "30%", width: "35%", display: "inline-block"}} onClick={navigateHome}> LOGIN </button>
              {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
            </div> 
          </div>
        </div>

        <img src={Simoun} alt="School Logo" height={150} width={150} color="#3C3A1E" style={{position: "absolute", right: "0%", bottom: "0%", width: "60%", height: "auto"}}/>
      </div>
    </div>
  )
}

export default TeacherLogin;