    import React, { useState, useEffect, useRef } from 'react';

    import { db } from '../Homepage/firebase-config'; //Firebase
    import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
    import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
    import { deleteUser as deleteAuthUser } from 'firebase/auth';


    import { Header } from '../Homepage/components'; // Components
    import { Navbar } from '../Homepage/components';
    import AdminSidebar from '../Homepage/components/AdminSidebar';
    import { useStateContext } from '../Homepage/contexts/ContextProvider';

    import AdminManageTeachersModal from '../Homepage/pages/Modals/AdminManageTeachersModal'; // Modals
    import UpdateStudentsModal from '../Homepage/pages/Modals/UpdateStudentsModal'

    import '../Homepage/pages/ManageStudents.css'; // CSS

    function AdminManageTeachers() {
        {/* USE-STATES FOR FIREBASE */ }
        const [newFirstname, setNewFirstname] = useState("");
        const [newMiddlename, setNewMiddlename] = useState("");
        const [newLastname, setNewLastname] = useState("");
        const [newSection, setNewSection] = useState("");
        const [newEmail, setNewEmail] = useState("");
        const [prcNumber, setPrcNumber] = useState("");
        const [newUsername, setNewUsername] = useState("");
        const [newPassword, setNewPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");

        const teachersCollectionRef = collection(db, "teachers");

        const [updateFirstname, setUpdateFirstname] = useState('');
        const [updateMiddlename, setUpdateMiddlename] = useState('');
        const [updateLastname, setUpdateLastname] = useState('');
        const [updateSection, setUpdateSection] = useState("");
        const [updateEmail, setUpdateEmail] = useState("");
        const [updatePrcNumber, setUpdatePrcNumber] = useState(0);
        const [updateUsername, setUpdateUsername] = useState('');
        const [updatePassword, setUpdatePassword] = useState('');
        const [updateStatus, setUpdateStatus] = useState(false);

        {/* STATE CONTEXT FOR SIDEBAR */}
        const { activeMenu } = useStateContext();
        const [selectedUsers, setSelectedUsers] = useState([]);
        const [users, setUsers] = useState([]);
        
        {/* USE-STATES FOR MODAL */ }
        const [show, setShow] = useState(false);
        const [anotherShow, setAnotherShow] = useState(false);

        // Define handleCheckboxChange function inside the AdminManageTeachers component
        const handleCheckboxChange = (event, userId) => {
            // Logic to handle checkbox change
            // You can perform actions based on the checkbox change here
            // For example, updating the selectedUsers state
            const isChecked = event.target.checked;
        
            if (isChecked) {
              // Add the user ID to the selectedUsers state
              setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, userId]);
            } else {
              // Remove the user ID from the selectedUsers state
              setSelectedUsers((prevSelectedUsers) =>
                prevSelectedUsers.filter((id) => id !== userId)
              );
            }
        };
  

        // Function to check if all selected teachers are verified
        const allSelectedVerified = selectedUsers.every((userId) => {
          const teacher = users.find((user) => user.id === userId);
          return teacher && teacher.status; // Checks if the teacher's status is true (verified)
        });

        const auth = getAuth();
        
        {/* CREATE FUNCTION TO DATABASE */ }
        const createUser = async () => {
            if (
                newFirstname.trim() === '' ||
                prcNumber === '' ||
                newSection.trim() === '' ||
                newEmail.trim() === '' ||
                newUsername.trim() === '' ||
                newPassword.trim() === ''
            ) {
                alert('Please fill in all required fields.');
            } else if (newPassword.trim().length < 6) {
                alert('Password should be at least 6 characters long.');
            } else {
                try {
                    const newPasswordValue = newPassword;
                    const userCredential = await createUserWithEmailAndPassword(auth, newEmail, newPasswordValue);
                    const user = userCredential.user;
        
                    // Add the user to Firestore
                    await addDoc(teachersCollectionRef, {
                        firstname: newFirstname,
                        middlename: newMiddlename,
                        lastname: newLastname,
                        section: newSection,
                        email: newEmail,
                        prcnumber: prcNumber,
                        username: newUsername,
                        password: newPassword,
                        status: false, // Assuming initial status as false for new users
                        uid: user.uid
                    });
        
                    window.alert('User created successfully!');
                    getUsers();
                    setShow(false);
                } catch (error) {
                    console.error('Error creating user: ', error.message);
                }
            }
        };

        {/* READ FUNCTION FROM DATABASE */ }
        const getUsers = async () => {
        try {
            const data = await getDocs(teachersCollectionRef);
            setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error("Error getting users: ", error);
        }
        };

        useEffect(() => {
            getUsers(); // Fetch users on initial render
        }, []);

        
        {/* UPDATE FUNCTION FROM DATABASE */ }
        const applyTeacherUpdates = async (teacher) => {
            try {
              const updatedTeacherData = {
                firstname: updateFirstname || teacher.firstname,
                middlename: updateMiddlename || teacher.middlename,
                lastname: updateLastname || teacher.lastname,
                section: updateSection || teacher.section,
                email: updateEmail || teacher.email,
                prcnumber: updatePrcNumber || teacher.prcnumber,
                username: updateUsername || teacher.username,
                password: updatePassword || teacher.password,
                status: updateStatus || teacher.status,
              };
          
              const teacherDocRef = doc(db, "teachers", teacher.id);
          
              await updateDoc(teacherDocRef, updatedTeacherData);
              getUsers();
              setAnotherShow(false);
              // Reset the state values after updating
              setUpdateFirstname("");
              setUpdateMiddlename("");
              setUpdateLastname("");
              setUpdateSection("");
              setUpdateEmail("");
              setUpdatePrcNumber("");
              setUpdateUsername("");
              setUpdatePassword("");
              setUpdateStatus(false);
          
              window.alert("Teacher's information has been updated successfully!");
            } catch (error) {
              console.error("Error updating teacher: ", error);
            }
          };

        {/* DELETE FUNCTION IN DATABASE */ }
        const deleteUser = async (id, email) => {
          try {
              const teacherDoc = doc(teachersCollectionRef, id); // Firestore document reference
              await deleteDoc(teacherDoc); // Delete user from Firestore
  
              const auth = getAuth();
              await deleteAuthUser(auth.currentUser); // Delete user from Firebase Authentication
  
              window.alert(`${email} has been deleted successfully`);
              getUsers(); // Fetch updated teachers after deletion
          } catch (error) {
              console.error('Error deleting user: ', error);
          }
        };

        // Function to handle verification and rejection for teachers
        const handleTeacherVerification = async (confirm) => {
        try {
        for (const userId of selectedUsers) {
        const teacher = users.find((user) => user.id === userId);
        if (teacher) {
          const teacherDocRef = doc(db, 'teachers', teacher.id);
          if (confirm) {
            // Verify the teacher if confirmed
            await updateDoc(teacherDocRef, { status: true });
            window.alert(`${teacher.firstname}'s status has been verified`);
          } else {
            // If rejected, delete the teacher if they are not verified
            if (!teacher.status) {
                  await deleteDoc(teacherDocRef);
                window.alert(`${teacher.username} has been rejected and deleted`);
                } else {
                window.alert(`${teacher.username} cannot be deleted as they are verified`);
                    }
                  }
                }
              }

              setSelectedUsers([]); // Clear the selected users after verification/deletion
              getUsers(); // Fetch updated teachers
            } catch (error) {
              console.error('Error updating/deleting teachers: ', error);
            }
        };
  

        return (
        <div>
            <div className="flex relative dark:bg-main-dark-bg">
                <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}></div>

                {activeMenu ? (
                    <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
                        <AdminSidebar />
                    </div>
                    ) : (
                    <div className="w-0 dark:bg-secondary-dark-bg">
                        <AdminSidebar />
                    </div>
                )}

                <div style={{backgroundColor: "#E3DECA"}} className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${activeMenu ? 'md:ml-72' : 'flex-2'}`}>
                    <div className={"fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full"} style={{backgroundColor: "#E3DECA"}}>
                        <Navbar/>
                        <div className="ManageStudents" style={{paddingLeft: '25px'}}>
                            <Header category="Manage Teachers" title="List of Official Teachers of Meycauyan National High School"/>
                            <div>
                                <b>
                                    <button onClick={() => setShow(true)} style={{fontFamily: 'LoginTitleFont', fontSize: '15px', color: '#E3DECA', backgroundColor: '#3C3A1E', borderRadius: '17px', width: '12.5%', height: '30px', marginRight: '30px'}}>
                                        Create New Teacher
                                    </button>{'\u00A0'}
                                    <AdminManageTeachersModal title="Create A New Teacher" show={show} onClose={() => setShow(false)}>
                                        <p>
                                            <label>First Name:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter first name...'
                                                onChange={(event) => setNewFirstname(event.target.value)}
                                            /><br/><br/>
                                            <label>Middle Name:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter middle name...'
                                                onChange={(event) => setNewMiddlename(event.target.value)}
                                            /><br/><br/>
                                            <label>Last Name:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter last name...'
                                                onChange={(event) => setNewLastname(event.target.value)}
                                            /><br/><br/>
                                            <label>Section:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter last name...'
                                                onChange={(event) => setNewSection(event.target.value)}
                                            /><br/><br/>
                                            <label>Email:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter email...'
                                                onChange={(event) => setNewEmail(event.target.value)}
                                            /><br/><br/>
                                            <label>PRC Number:</label>
                                            <input
                                                type="int"
                                                className='LoginInputField'
                                                placeholder='Enter PRC number...'
                                                onChange={(event) => setPrcNumber(event.target.value)}
                                            /><br/><br/>
                                            <label>Username:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter username...'
                                                onChange={(event) => setNewUsername(event.target.value)}
                                            /><br/><br/>
                                            <label>Password:</label>
                                            <input
                                                className='LoginInputField'
                                                placeholder='Enter password...'
                                                type='password'
                                                onChange={(event) => setNewPassword(event.target.value)}
                                            /><br/><br/>
                                            <b>
                                                <button onClick={createUser} className='LoginButtons' style={{marginLeft: "30%", width: "40%", display: "inline-block"}}>
                                                    Create New Teacher
                                                </button>
                                            </b>
                                        </p>
                                    </AdminManageTeachersModal>
                                </b>    
                                {/* Verification Action */}
        {!allSelectedVerified && selectedUsers.length > 0 && (
          <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
            <strong>Verification Action:</strong>
            <button
              style={{
                fontFamily: 'LoginTitleFont',
                fontSize: '15px',
                color: '#E3DECA',
                backgroundColor: '#3C3A1E',
                borderRadius: '17px',
                width: '10%',
                height: '30px',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleTeacherVerification(true)}
            >
              Confirm Selected
            </button>
            <button
              style={{
                fontFamily: 'LoginTitleFont',
                fontSize: '15px',
                color: '#E3DECA',
                backgroundColor: '#3C3A1E',
                borderRadius: '17px',
                width: '10%',
                height: '30px',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={() => handleTeacherVerification(false)}
            >
              Reject Selected
            </button>
          </div>
        )}
                            </div>
                            <br />
                            <table style={{width: "98.5%", height: "100%", marginBottom: "2%"}}> 
                                <thead>
                                    <tr>
                                        <th style={{width: "5%"}}></th>
                                        <th>First Name</th>
                                        <th>Middle Name</th>
                                        <th>Last Name</th>
                                        <th>Section</th>
                                        <th>Username</th>
                                        <th>Password</th>
                                        <th>Email</th>
                                        <th>PRC Number</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody style={{overflowY: "auto"}}>
                                {users.map((user) => {
                                    return (
                                        <tr key={user.id}>
                                            <td style={{ border: '0px' }}>
                                              {!user.status ? (
                                                <input
                                                  type="checkbox"
                                                  checked={selectedUsers.includes(user.id)}
                                                  onChange={(event) => handleCheckboxChange(event, user.id)}
                                                  style={{ transform: 'scale(0.8)' }}
                                                />
                                              ) : (
                                                <input
                                                  type="checkbox"
                                                  checked={user.status || false}
                                                  disabled
                                                  style={{ transform: 'scale(0.8)' }}
                                                />
                                              )}
                                            </td>
                                            <td><h1>{user.firstname}</h1></td>
                                            <td><h1>{user.middlename}</h1></td>
                                            <td><h1>{user.lastname}</h1></td>
                                            <td><h1>{user.section}</h1></td>
                                            <td><h1>{user.username}</h1></td>
                                            <td><h1>{user.password}</h1></td>
                                            <td><h1>{user.email}</h1></td>
                                            <td><h1>{user.prcnumber}</h1></td>
                                            <td><h1>{user.status ? 'Verified' : 'Not Verified'}</h1></td>
                                            <td style={{margin: "auto"}}>
                                            <button onClick={() => setAnotherShow(true)} style={{ fontFamily: 'LoginTitleFont', fontSize: '15px', margin: '2%', color: '#E3DECA', backgroundColor: '#3C3A1E', borderRadius: '17px', width: '80px', height: '25px' }}>Update</button>
                                                <UpdateStudentsModal title="Update Student's Information" show={anotherShow} onClose={() => setAnotherShow(false)}>
                                                    <p>
                                                        <label>First Name:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter first name...'
                                                          onChange={(event) => setUpdateFirstname(event.target.value)}
                                                          value={updateFirstname}
                                                        /><br/><br/>
                                                        <label>Middle Name:</label>
                                                        <input
                                                            className='LoginInputField'
                                                            placeholder='Enter middle name...'
                                                            onChange={(event) => setUpdateMiddlename(event.target.value)}
                                                            value={updateMiddlename}
                                                        /><br/><br/>
                                                        <label>Last Name:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter last name...'
                                                          onChange={(event) => setUpdateLastname(event.target.value)}
                                                          value={updateLastname}
                                                        /><br/><br/>
                                                        <label>Section:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter section...'
                                                          onChange={(event) => setUpdateSection(event.target.value)}
                                                          value={updateSection}
                                                        /><br/><br/>
                                                        <label>Email:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter email...'
                                                          onChange={(event) => setUpdateEmail(event.target.value)}
                                                          value={updateEmail}
                                                        /><br/><br/>
                                                        <label>PRC Number:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter PRC number...'
                                                          onChange={(event) => setUpdatePrcNumber(event.target.value)}
                                                          value={updatePrcNumber}
                                                        /><br/><br/>
                                                        <label>Username:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter username...'
                                                          onChange={(event) => setUpdateUsername(event.target.value)}
                                                          value={updateUsername}
                                                        /><br/><br/>
                                                        <label>Password:</label>
                                                        <input
                                                          className='LoginInputField'
                                                          placeholder='Enter password...'
                                                          type='password'
                                                          onChange={(event) => setUpdatePassword(event.target.value)}
                                                          value={updatePassword}
                                                        /><br/><br/>
                                                        <b>
                                                          <button onClick={() => applyTeacherUpdates(user)} className='LoginButtons' style={{marginLeft: "30%", width: "40%", display: "inline-block"}}>
                                                            Apply Changes
                                                          </button>
                                                        </b>
                                                    </p>
                                                </UpdateStudentsModal>
                                                <button style={{ fontFamily: 'LoginTitleFont', fontSize: '15px', margin: '2%', color: '#E3DECA', backgroundColor: '#3C3A1E', borderRadius: '17px', width: '80px', height: '25px' }} onClick={() => {deleteUser(user.id)}}>Delete</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }

    export default AdminManageTeachers;
